import './internals.scss';
import './sub-service-acc.scss';
import './singles-contact.scss';
import './bio.scss';
import './singles-broker-blog.scss';
import './lenders.scss';
import './broker-coming-soon.scss';
import './singles-child-location.scss';


import Tabs from 'src/classes/Tabs';
import { on_ready } from 'src/js/utils';
import { add_to_cart_ajax_init } from 'src/woocommerce/js/add_to_cart';

on_ready(()=>{
    product_tabs_init();
    add_to_cart_ajax_init();
});

function product_tabs_init(){
    document.querySelectorAll('.custom_tabs').forEach(el=> new Tabs(el));
}